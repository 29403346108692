import {useEffect, useState} from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/QuotaList.scss';
import {Quota} from "./Quota";
import {QuotaCellsTable} from "./QuotaCellsTable";

export function QuotaList() {
    const [error, setError] = useState<string | undefined>();
    const [quotas, setQuotas] = useState<Quota[] | undefined>();
    
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        async function makeRequest() {
            try {
                const result = await fetch(`api/quotas?id=${searchParams.get('id')}&p=${searchParams.get('p')}`);
                if (result.ok) {
                    const list = await result.json();
                    setQuotas(list);
                }
                else {
                    setError(await result.json());
                }
            }
            catch (e) {
                setError((e as Error).message);
            }
        }

        makeRequest();
    }, []);

    if (error) {
        return <>
            <h1>Error while retrieving quotas</h1>
            <p>{error}</p>
        </>;
    }

    if (!quotas) {
        return <>
            <LoadingSkeleton />
            <LoadingSkeleton />
        </>;
    }

    if (quotas.length === 0) {
        return <>
            <h1>Hmmm...</h1>
            <p>No quotas found for this survey.</p>
        </>
    }
    
    return <>
        {quotas.map(quota => <div key={quota.name}>
            <h1>{quota.name}</h1>
            <QuotaCellsTable cells={quota.cells} />
        </div>)}
        </>;
}

function LoadingSkeleton() {
    const loadingHeaderCell = (flexWidth: number) => <span className="react-loading-skeleton" style={{flex: flexWidth}}>{"\u200C"}</span>;
    
    return <>
        <h1><Skeleton width={150} inline={true} /></h1>
        <div style={{maxWidth: 850}}>
            <span className="loader-quota-list">
                {loadingHeaderCell(1)}
                {loadingHeaderCell(1)}
                {loadingHeaderCell(1)}
                {loadingHeaderCell(1)}
                {loadingHeaderCell(1)}
                {loadingHeaderCell(1)}
            </span>
            <Skeleton count={5} />
        </div>
    </>
}

