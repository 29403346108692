import React from 'react';
import savantaLogo from './logos/Savanta_Logo_White_Pink_RGB.svg';
import './styles/App.scss';
import {QuotaList} from "./QuotaList";

function App() {
    return (
        <div>
            <header>
                <div className="img-wrapper">
                    <img src={savantaLogo} alt="logo"/>
                </div>
            </header>
            <article>
                <QuotaList />
            </article>
        </div>
    );
}

export default App;
