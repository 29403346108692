import {QuotaCell} from "./QuotaCell";
import {Column, useSortBy, UseSortByColumnProps, useTable} from "react-table";
import {useMemo} from "react";
import './styles/QuotaCellsTable.scss';

export function QuotaCellsTable(props: { cells: QuotaCell[] }) {
    const columns = useMemo<Column<QuotaCell>[]>(() => [
        {
            Header: "Cell",
            accessor: 'name',
            sortType: 'alphanumeric',
        },
        {
            Header: 'Completed',
            accessor: 'completes',
        },
        {
            Header: 'Target',
            accessor: 'target',
            Cell: ({row}) => (
                row.original.target === -1 ? <span className="no-target">No target</span> : row.original.target
            ),
        },
        {
            Header: 'Gap',
            accessor: 'gap',
            Cell: ({row}) => (
                row.original.target === -1 ? <span className="no-target">{row.original.gap}</span> : row.original.gap
            )
        },
        {
            Header: 'Cap',
            accessor: 'cap',
            Cell: ({row}) => (
                row.original.cap === -1 ? <span className="no-target">No cap</span> : row.original.cap
            )
        },
        {
            Header: 'Progress',
            accessor: 'progress',
            Cell: ({row}) => {
                const cell = row.original;
                if (cell.target === -1 || cell.target === 0) {
                    return <span className="no-target">No target</span>;
                }

                const totalPercent = cell.completes === 0
                    ? 0
                    : Math.floor(cell.completes * 100 / cell.target);

                return <>
                    <div className="progress">
                        <div className="progress-inner" style={{
                            width: `${Math.min(totalPercent, 100)}%`
                        }}/>
                    </div>
                    <span>{totalPercent}%</span>
                </>;
            }

        },
    ], []);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
        data: props.cells,
        columns: columns
    }, useSortBy);

    return (
        <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                            {column.render('Header')}
                            <span
                                className={(column as unknown as UseSortByColumnProps<QuotaCell>).isSorted ? ((column as unknown as UseSortByColumnProps<QuotaCell>).isSortedDesc ? 'sort-desc' : 'sort-asc') : ''}>
                        </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    const isCompleteCell = row.original.target > 0 && row.original.gap === 0;

                    return (
                        <tr {...row.getRowProps(r => ({
                            ...r,
                            style: {
                                background: isCompleteCell ? '#E8F1F0' : r.style?.background
                            }
                        }))}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                }
            )}
            </tbody>
        </table>
    )
}